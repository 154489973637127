.nav-bar {
  width: 100%;
  height: 60px;
  
  display: block;
  position:fixed; 
  top: 0;
  left: 0;
  z-index: 99;

  background: #fff;
  border-bottom: 1px solid #eee;
}

.nav-bar .branding {
  float: left;
  margin: 18px 0 0 0;
}