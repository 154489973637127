input {
  width: 100%;
  -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box;    
  box-sizing: border-box;
  display: block;
  outline: none;
  border: 2px solid #e2e2e2;
  font-size: 18px;
  font-weight: 400;
  padding: 12px 15px 12px 15px;
  border-radius: 3px;
  margin: 0 auto 10px auto !important;
  appearance: none;
  -webkit-appearance: none;
  overflow: hidden;
}

textarea {
  width: 100%;
  -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box;    
  box-sizing: border-box;
  display: block;
  outline: none;
  border: 2px solid #e2e2e2;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 10px 10px 10px;
  border-radius: 3px;
  margin: 0 auto 10px auto !important;
  appearance: none;
  -webkit-appearance: none;
}