@import url("https://use.typekit.net/vmo5cqg.css");

body {
  background: #fff;
  color: #283037;
  font-family: "museo-sans";
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

a {
  color: #396af1;
  text-decoration: none;
}
