.nav-bar-links-item {
  position: relative;
  float: left;
  margin: 0 0 0 10px;
}

.nav-bar-links-item a {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: -0.5px;
  margin-left: 20px;
  text-decoration: none;
  line-height: 60px;
  color: #000;
  border-bottom: 3px solid transparent;
}

.nav-bar-links-item a:hover {
  border-bottom: 3px solid #f1396a;
  transition: border-bottom 0.2s ease;
}

.dashboard .nav-bar-links-item.dashboard a,
.profile .nav-bar-links-item.profile a,
.settings .nav-bar-links-item.settings a {
  border-bottom: 3px solid #396af1;
}